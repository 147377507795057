<div class="grid-wrapper"  [ngClass]="className" #gridWrapperIdentifier>
    <div class="table-wrapper" [ngStyle]="moduleType?.style?.tableWrapper">
        <perfect-scrollbar [ngStyle]="moduleType?.style?.tableStyle" [ngClass]="{'no-header':(!showHeader || !isTable)}">
            <table *ngIf="isTable">
                <thead *ngIf="showHeader">
                    <tr *ngIf="ngxCols" id="ngtable-row">
                        <th resizable *ngFor="let col of ngxCols" [ngClass]="{'d-none':!col.isChecked || col.showCol, 'sort-active':sortActive.colObj?.id===col?.id}" class="{{col.style?.['th-class']}} {{col.style?.['custom-th-class']}}">
                        <div class="table-th {{col.style?.['th-class']}}"
                        [ngClass]="{'cursor-pointer sort-hover':col.sort,'bulkSelect':col.component === 'bulkSelect'}">
                            <span *ngIf="col.component !== 'bulkSelect'" class="table-header" (click)="sortOperation(col)">
                                <span class="col-name" translate>{{col.name | translate}}</span>
                                <i class="icon icon-Caret-up" *ngIf="col.sort" [ngClass]="{'icon-Caret-up':(sortActive.colObj?.id !== col?.id || sortActive.sortDirection==='down'),'icon-Caret-down':(sortActive.colObj?.id === col?.id && sortActive.sortDirection==='up')}"></i>
                            </span>
                            <ng-container *ngIf="col.component === 'bulkSelect'">
                                <label class="checkbox" for="header">
                                    <input type="checkbox" name="checkbox" id="header"
                                        [disabled]="loadingData || bulkSelect || !permissions.write_access"
                                        [checked]="!loadingData && (checkSelectStatus() || bulkSelect)" (change)="trackChange('all')">
                                    <div class="checkbox-section"><i class="icon icon-Check"></i></div>
                                </label>
                                <span class="select-action-header" *ngIf="bulkSelect || itemSelected?.size">
                                    <app-select-action-header [gridInfo]="moduleType" [data]="itemSelected" [items]="ngxItems"
                                        (bulkSelect)="onBulkSelectAction($event)" (refreshGrid)="onBulkSuccess($event)">
                                    </app-select-action-header>
                                </span>
                            </ng-container>
                        </div>                    
                        </th>
                    </tr>
                </thead>
                <tbody *ngIf="ngxItems">
                    <ng-container *ngFor="let item of ngxItems?.data; let rowIndex=index;">
                        <tr>
                            <td *ngFor="let col of ngxCols;let colIndex=index ; trackBy: trackScrollRows" class="{{col.style?.['td-class']}}" [ngClass]="{'d-none' : !col.isChecked || col.showCol,'bulkSelect':col.component === 'bulkSelect'}">
                                <ng-container *ngIf="col.component === 'bulkSelect'">
                                    <label class="checkbox" for="item-{{rowIndex}}">
                                        <input type="checkbox" name="checkbox" id="item-{{rowIndex}}"
                                            [disabled]="bulkSelect || !permissions.write_access" [checked]="bulkSelect || itemSelected.has(item.id)"
                                            (change)="trackChange('item',item)">
                                        <div class="checkbox-section"><i class="icon icon-Check"></i></div>
                                    </label>
                                </ng-container>
                                <ng-container *ngIf="col.component == 'common-mapping'">
                                    <div [ngClass]="col.needExpander?'d-flex align-items-center':''">
                                        <ng-container *ngIf="col.needExpander">
                                            <i class="icon exapandable-controller cursor-pointer" [ngClass]="item.isExpanded?'icon-Minus':'icon-Add'" (click)="rowExpanderToggle(col,item)"></i>
                                        </ng-container>
                                        <app-common-mapping [col]="col" [item]="item" [moduleType]="moduleType" (navigationTrigger)="onNavigationTrigger($event)" (trendActionTrigger)="onTrendActionTrigger($event)"></app-common-mapping>
                                    </div>
                                </ng-container>
                                <!-- build-action -->
                                <ng-container *ngIf="col.component == 'build-action'">
                                    <app-builds-action [actionData]="item" [col]="col" [actionType]="'buildActions'" [moduleType]="moduleType" (bulkSelect)="onBulkSelectAction($event)" (refreshGrid)="onBulkSuccess($event)">
                                    </app-builds-action>
                                </ng-container>
                                <ng-container *ngIf="col.component == 'default-action'">
                                    <app-common-action-dropdown [disableIcon]="noData"></app-common-action-dropdown>
                                </ng-container>
                                <ng-container *ngIf="col.component == 'common-grid-action'">
                                    <app-common-grid-actions [col]="col" [item]="item" [moduleType]="moduleType"></app-common-grid-actions>
                                </ng-container>
                                <ng-container *ngIf="col.component == 'team-member-action'">
                                    <app-user-management [actionData]="item"
                                        [componentDetail]="componentDetail">
                                    </app-user-management>
                                </ng-container>
                                <ng-container *ngIf="col.component == 'roles-action'">
                                    <app-roles-action [actionData]="item" [componentDetail]="componentDetail"
                                        [col]="col">
                                    </app-roles-action>
                                </ng-container>
                                <ng-container *ngIf="col.component == 'notification-action'">
                                    <notifications-action [actionData]="item"
                                        [componentDetail]="componentDetail" [col]="col">
                                    </notifications-action>
                                </ng-container>
                                <ng-container *ngIf="col.component == 'harmonisation-action'">
                                    <app-harmonization-actions [actionData]="item" [col]="col" [moduleType]="moduleType" [meta]="collection.meta" [pageSize]="selectedPage_size" (refreshGrid)="onRefreshGrid($event)">
                                    </app-harmonization-actions>
                                </ng-container>
                            </td>
                        </tr>
                        <tr *ngIf="item.isExpanded" class="no-border-btm">
                            <td [colSpan]="ngxCols.length">
                                <app-recursive-inner-grid [item]="item" [gridDetails]="innerGridDetails"></app-recursive-inner-grid>
                        </td>
                        </tr>
                       
                    </ng-container>
                </tbody>
            </table>
            <div *ngIf="loadingData" class="nodata"
                [ngStyle]="moduleType?.style?.noDataStyle"
                #noDataAvailableIdentifier>
                <div *ngIf="noData" translate>
                    <div class="no-data-found">
                        <div class="no-data-text" translate>{{customNoDataText}}</div>
                    </div>
                </div>
                <div *ngIf="!noData" class="loading-panel"></div>
            </div>
            <ng-container *ngIf="moduleType.name == 'segmentation' && ngxItems?.data.length > 0 && !loadingData">
                <app-segmentation-grid [items]="ngxItems.data" [moduleType]="moduleType">
                </app-segmentation-grid>
            </ng-container>
            <ng-container *ngIf="moduleType.name == 'available_products' && ngxItems?.data.length > 0">
                <app-available-product-grid [ngxItems]="ngxItems" (quickOpen)="onQuickClick($event)" (noteOpen)="openNotification($event)" (deepOpen)="openDeepCompare($event)" (trendCompareOpen)="miscTrigger.emit($event)"></app-available-product-grid>
            </ng-container>
            <ng-container *ngIf="moduleType.name === 'available_stores' && ngxItems?.data.length > 0">
                <app-available-store-grid [ngxItems]="ngxItems" ></app-available-store-grid>
            </ng-container>
        </perfect-scrollbar>
    </div>
    <div *ngIf="moduleType?.pagination?.show && collection" class="box foot" aria-label="pagination">
        <div class="grid-footer" [ngStyle]="moduleType?.style?.paginationStyle">
            <div class="row grid-row">
                <div class="col-sm-6 pagination-left-side">
                    <ng-select #select [(ngModel)]="selectedPage_size" [notFoundText]="'No Data Found' | translate"
                        [loadingText]="'Loading...' | translate" appendTo="body" [ngModelOptions]="{standalone: true}"
                        (change)="changePageSize($event)">
                        <ng-option *ngFor="let size of pageSize" [value]="size.value" translate>{{size.value}}
                        </ng-option>
                    </ng-select>

                    <span class="goToPage ms-2" *ngIf="gridGoToPage">
                        <div class="input-container">
                            <input type="number" id="page_limit" min="1" [ngClass]="{'sm-pg': pgType === 'sm'}"
                                placeholder="{{goToPlaceholder}}"
                                onkeypress="return event.charCode >= 48 && event.charCode <= 57" autocomplete="off" (keydown.enter)="goToPageFunc()" [(ngModel)]="goToPage" [disabled]="!ngxItems?.data?.length" />
                                <i class="icon icon-Arrow-right enter-state" *ngIf="goToPage"
                                    (click)="goToPageFunc()"></i>
                        </div>
                    </span>
    
                    <span class="page-info" *ngIf="!loadingData && !noData && maxSize>1">{{'Showing' | translate}}
                        {{(((currentPage-1)*selectedPage_size)+1)}}-{{(
                        (((currentPage-1)*selectedPage_size)+1)+selectedPage_size-1 ) >
                        collection.meta.total_count?collection.meta.total_count:(((currentPage-1)*selectedPage_size)+1)+selectedPage_size-1}}
                        {{'of' | translate}} {{collection.meta.total_count | numberSuffix  : 2}}</span>
                </div>
                <div class="col-sm-6 pagination-right-side">
                    <div class="float-end">
                        <ngb-pagination [disabled]="loadingData" (pageChange)="pageChange($event)" class="pagination-block"
                            [collectionSize]="collection.meta.total_count"
                            [(page)]="currentPage"
                            [maxSize]="maxSize" [pageSize]="selectedPage_size"
                            [boundaryLinks]="true">
                        </ngb-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-quick-compare></app-quick-compare>