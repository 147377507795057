<div class="menu-container">
    <div class="product-logo-container">
    <img src="../../../../assets/images/DS Icon.svg" alt="">
    <span>Anchanto Digital Shelf</span>
</div>
<div class="menu-wrapper">
    <div class="secondary-toggler cursor-pointer" (click)="commonFunctions.toggleSecondaryMenu('callFromClick'); closeAccordion()">
        <i class="icon icon-Caret-down"></i>
    </div>
    <div class="menu">
        <ul class="primary-menu-items-container">
            <ng-container *ngFor="let menu of menuList;let i = index">
                <ng-container *ngIf="menu.subMenuList.length; else showModuleWithoutAccordion">
                    <li 
                        id="menu.moduleName"
                        [ngClass]="{'pdisabled disabled user-event-none':!menu.isPermission}"
                        [ngClass]="{'pdisabled disabled':!menu.isPermission}"
                        (click)="onMenuClick(menu, i)"
                        [ngClass]="{'bottom-menu-items-container' :menu.moduleName === 'Account Settings'}"
                        [ngClass]="{'accordionOpen': myAccordion.isExpanded(i.toString())}"
                        *ngIf="menu.isPermission"
                        >
                        <ngb-accordion #myAccordion="ngbAccordion" [closeOthers]="false" [destroyOnHide]='true' [activeIds]="activeIds" >
                            <ngb-panel id="{{i}}">
                                <ng-template ngbPanelTitle>
                                    <div class="menu-list-item d-flex justify-content-between align-items-center cursor-pointe w-100" 
                                        [class.collapsed]="myAccordion.isExpanded(i.toString())"
                                        [ngClass]="{'active':router.url.includes(menu.path)}">
                                        <a [ngClass]="{'user-event-none':!menu.isPermission}">
                                            <div class="d-flex gap-8">
                                                <i [class]="menu.icon" (click)="commonFunctions.toggleSecondaryMenu('callFromMenu')"></i>
                                                <span class="menu-text">{{menu.tooltip}}</span>
                                            </div>
                                        </a>
                                        <div [class.rotate]="!myAccordion.isExpanded(i.toString())" class="d-flex">
                                            <i class="accordion-icon" [ngClass]=" !myAccordion.isExpanded(i.toString()) ? 'icon-Caret-up' : 'icon-Caret-up'"></i>
                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <ng-container *ngFor="let item of menu.subMenuList">
                                        <div 
                                        [class.hide-panel-content]="menu.subMenuList.length" class="accordion-sub-body d-flex align-items-center justify-content-between"
                                        [ngClass]="{'active':router.url.includes(item.path)}"
                                        (click)="onSubmoduleClick(item)"> 
                                            <div class="d-flex align-items-center gap-8">
                                                <div class="icon-block">
                                                    <i class="icon" [ngClass]="item.icon"></i>
                                                </div>
                                                <div class="menu-text">
                                                    <div class="only-text" translate>{{item.name}}</div>
                                                </div>
                                            </div>
                                            <div class="count text-center" *ngIf="item.count && item.showCount">
                                                <span>{{item.count |  numberSuffix  : 2}}</span>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion>                        
                    </li>
                </ng-container>
                <ng-template #showModuleWithoutAccordion>
                    <li [routerLinkActive]="'active'"
                    id="menu.moduleName"
                    [ngClass]="{'pdisabled disabled user-event-none':!menu.isPermission}"
                    [ngClass]="{'active':router.url.includes(menu.path),'pdisabled disabled':!menu.isPermission}"
                    [routerLink]="menu.path && menu.isPermission?menu.path:[]"
                    (click)="commonFunctions.toggleSecondaryMenu('callFromMenu');onMenuClick(menu, i)" *ngIf="menu.isPermission">
                    <a [ngClass]="{'user-event-none':!menu.isPermission}" class="d-flex gap-8" >
                        <i [class]="menu.icon" ></i>
                        <span class="menu-text">{{menu.tooltip}}</span>
                    </a>
                </ng-template>
            </ng-container>

        </ul>
    </div>
    <!-- <app-secondary-menu *ngIf="showSecondaryMenu"></app-secondary-menu> -->
</div>
<div class="logout-wrapper">
    <div (click)="logout()" class="logout-button">
        <a class="d-flex gap-8 align-items-center">
            <i class="icon icon-Logout"></i>
            <span translate>Logout</span>
        </a>
    </div>
</div>
</div>
