import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { GlobalService } from '@ds-shared/common-services/http-services/global.service';
import { CommonFunctionsService } from '@ds-shared/common-services/utility-services/common-functions.service';
import { CustomValidationsService } from '@ds-shared/common-services/utility-services/custom-validations.service';
import { NotificationService } from '@ds-shared/common-services/utility-services/notification.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public forgotPasswordForm: UntypedFormGroup;
  constructor(
    private globalService: GlobalService,
    public commonFunctions: CommonFunctionsService,
    private notificationService: NotificationService,
    private router: Router
  ) { }
  private destroy$: Subject<boolean> = new Subject();
  ngOnInit(): void {
    this.forgotPasswordForm = new UntypedFormGroup({
      'email_id': new UntypedFormControl(null, [ Validators.required, Validators.pattern(/^[\w\.-]+@[\w\.-]+\.\w+$/)]),
    });
  }

  onSubmit() {
    if(!this.forgotPasswordForm.controls['email_id'].value) {
      this.forgotPasswordForm.controls['email_id'].markAsTouched();
      this.forgotPasswordForm.controls['email_id'].setErrors({ 'emailError': this.commonFunctions.getSpecificTranslation('Please enter email Id.')});
    }
    else {
      if(this.forgotPasswordForm.controls['email_id'].status !== 'INVALID') {
        this.globalService.sendResetPasswordLink({"email" : this.forgotPasswordForm.get('email_id').value}).pipe(takeUntil(this.destroy$)).subscribe((res: any)=>{
            this.notificationService.setMessage(res.context_code, res.summary ? res.summary  : this.commonFunctions.getSpecificTranslation("Something went wrong, please try again."));
        },
        (err) => {
          this.notificationService.setMessage(1100, (err.error.summary ? err.error.summary : this.commonFunctions.getSpecificTranslation("Something went wrong, please try again.")));
        }
        )
      }
      else {
        const inputElement = document.getElementById("emailID");
        inputElement.focus();
      }
    }
  }

  goBackToLogin() {
    this.router.navigate(['']);
  }

  public ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

  
}